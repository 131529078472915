import bootstrap from 'bootstrap'

document.addEventListener("DOMContentLoaded",function() {
    document.querySelectorAll('.acc-expander').forEach((element) => {
        element.addEventListener('click', (element) => {
            if (element.target.classList.contains('collapsed')) {
                element.target.text = 'Expand';
            } else {
                element.target.text = 'Collapse';
            }
        })
    });
});